
import { Component, Vue } from 'vue-property-decorator';
import Preloader from './components/Preloader.vue';
import BgVideo from './components/BgVideo.vue';
import MainContent from './components/MainContent.vue';
import ModalContent from './components/ModalContent.vue';

@Component({
  components: {
    Preloader,
    BgVideo,
    MainContent,
    ModalContent,
  },
})
export default class App extends Vue {}
