import Vue from 'vue'
import App from './App.vue'
import { loadScript } from './utils';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

setTimeout(() => {
  loadScript('/js/teema-cs01.js');
}, 500);
